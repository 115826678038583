'use client'
import CookieConsent from 'components/cookie-consent'
import Footer from 'v1/common-components/footer/Footer'
import * as React from 'react'

interface IPageLayout {
  children: React.ReactNode
  footerProps?: IFooter
}
export interface IFooter {
  showWhyZenadmin?: boolean
  showReviewSection?: boolean
  showFAQSection?: boolean
  showFavoriteToolsSection?: boolean
  showBlueCardSection?: boolean
  showWhiteCardSection?: boolean
  blueCardData?: {
    blueCardText1?: string
    blueCardText2?: string
    paragraph?: string
    buttonText?: string
    isFreeTrial?: boolean
  }
}
export default function PageLayout({children, footerProps}: IPageLayout) {
  return (
    <div>
      {children}
      <Footer {...footerProps} />
      <CookieConsent />
    </div>
  )
}
